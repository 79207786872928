import $api from "@/http";
export default class BaseModel {
  static _path = "";
  _oldData = {};
  constructor(data) {
    if (new.target === BaseModel) {
      throw new Error("Cannot instantiate abstract class BaseModel directly");
    }
    this.id = data.id;
    this.title = data.title;
    this._oldData = { ...data };
  }

  getData() {
    const dataToSave = {};
    for (let field in this) {
      if (field.startsWith("_")) continue;
      if (this[field]?.id) {
        if (this._oldData[field]?.id !== this[field].id) dataToSave[field] = this[field];
      } else if (this._oldData[field] !== this[field]) {
        dataToSave[field] = this[field];
      }
    }
    return dataToSave;
  }

  get isValid() {
    throw new Error("Method 'isValid()' must be implemented.");
  }
  get hasChanges() {
    return Object.keys(this.getData()).length > 0;
  }
  get err() {
    return this._hasError;
  }
  set err(data) {
    this._hasError = data;
  }

  async _create(data) {
    let resp = await $api.post(`${this.constructor._path}/`, data, {
      headers: { "Content-Type": 'multipart/form-data' },
    });
    if (resp.status >= 400) {
      this.err = true;
      console.error("Помилка ", resp.data);
      throw new Error("Операція не вдалася");
    }
    if (resp.data.id) {
      this.id = resp.data.id;
    }
    return resp;
  }
  async _update(id, data) {
    const resp = await $api.patch(`${this.constructor._path}/${id}/`, data, {
      headers: { "Content-Type": 'multipart/form-data' },
    });
    if (resp.status >= 400) {
      this.err = true;
      console.error("Помилка ", resp.data);
      throw new Error("Операція не вдалася");
    }
    return resp;
  }

  async _delete() {
    if (this.id) return await $api.delete(`${this.constructor._path}/${this.id}/`);
  }

  static async _load(id) {
    let resp = await $api.get(`${this._path}/${id}/`);
    return resp.data;
  }

  async save() {
    const formData = this.getData();
    if (this.id) {
      return await this._update(this.id, formData);
    }
    return await this._create(formData);
  }

  async delete() {
    return await this._delete();
  }

  static async load(id) {
    let resp = await this._load(id);
    return resp.data;
  }
}
