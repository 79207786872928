import { createRouter, createWebHistory } from "vue-router";
import { beforeEnter } from "@/services/RouterService";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Feedback from "@/views/Feedback.vue";
import Tariffs from "@/views/Tariffs.vue";
import FAQ from "@/views/FAQ.vue";
import DeleteProfile from "@/components/user/settings/DeleteProfile.vue";
import CloseProfile from "@/components/user/settings/CloseProfile.vue";
import DeleteAccount from "@/components/user/settings/DeleteAccount.vue";
import SuccessMessage from "@/views/Auth/SuccessMessage.vue";

// ? Pages
const User = () => import(/* webpackChunkName: "User" */ "@/views/User/User.vue"),
  Login = () => import(/* webpackChunkName: "Login" */ "@/views/Auth/Login.vue"),
  Register = () => import(/* webpackChunkName: "Register" */ "@/views/Auth/Register.vue"),
  Activate = () => import(/* webpackChunkName: "Register" */ "@/views/Auth/Activate.vue"),
  Reset = () => import(/* webpackChunkName: "Register" */ "@/views/Auth/Reset.vue"),
  ConfirmResignation = () => import(/* webpackChunkName: "Register" */ "@/views/Auth/ConfirmResignation.vue"),
  UserSettings = () => import(/* webpackChunkName: "UserSettings" */ "@/views/User/Settings/Settings.vue"),
  UserManagement = () => import(/* webpackChunkName: "UserManagement" */ "@/views/User/Management.vue"),
  NextRegister = () => import(/* webpackChunkName: "NextRegister" */ "@/views/Auth/NextRegister.vue"),
  Project = () => import(/* webpackChunkName: "Project" */ "@/views/Project/Main.vue"),
  Product = () => import(/* webpackChunkName: "Products" */ "@/views/Product/Main.vue"),
  Manufacture = () => import(/* webpackChunkName: "Manufacture" */ "@/views/Manufacture/Main.vue"),
  Main = () => import(/* webpackChunkName: "Main" */ "@/views/Main/Main.vue"),
  MainProjects = () => import(/* webpackChunkName: "MainProjects" */ "@/views/Main/Projects.vue"),
  MainUsers = () => import(/* webpackChunkName: "MainUsers" */ "@/views/Main/Users.vue"),
  Process = () => import(/* webpackChunkName: "Process" */ "@/views/Process/Main.vue"),
  About = () => import(/* webpackChunkName: "About" */ "@/views/About.vue"),
  ConfirmDeactivation = () => import(/* webpackChunkName: "Register" */ "@/views/Auth/ConfirmDeactivation.vue"),
  ConfirmDeletion = () => import(/* webpackChunkName: "Register" */ "@/views/Auth/ConfirmDeletion.vue");

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash };
    if (savedPosition) return savedPosition;

    return { left: 0, top: 0 };
  },
  routes: [
    {
      name: "Main",
      path: "/",
      component: Main,
      redirect: () => "/users",
      meta: { headerMenu: true },
      children: [
        {
          name: "MainProjects",
          path: "projects",
          meta: { headerMenu: true },
          component: MainProjects,
        },
        {
          name: "MainUsers",
          path: "users",
          meta: { headerMenu: true },
          component: MainUsers,
        },
      ],
    },
    {
      name: "ProfileCard",
      path: "/profile-card/:id",
      meta: { headerMenu: true },
      component: User,
    },
    {
      name: "UserProfile",
      path: "/profile/:id",
      component: UserSettings,
      meta: { mandatoryAuth: true, headerMenu: true },
    },
    {
      name: "UserProfileCreate",
      path: "/profile/create",
      component: UserSettings,
      meta: { mandatoryAuth: true, headerMenu: true },
    },
    {
      name: "UserManagement",
      path: "/management",
      component: UserManagement,
      meta: { mandatoryAuth: false, headerMenu: true },
    },
    {
      name: "Login",
      path: "/login",
      component: Login,
      meta: { noAuth: true },
    },
    {
      name: "Register",
      path: "/register",
      component: Register,
      meta: { noAuth: true },
    },
    {
      name: "Activate",
      path: "/activate/:id/:uid",
      component: Activate,
      meta: { noAuth: true },
    },
    {
      name: "Reset",
      path: "/restore",
      component: Reset,
      meta: { noAuth: false },
      // meta: { noAuth: true },
    },
    {
      name: "ConfirmResignation",
      path: "/confirm-resignation",
      component: ConfirmResignation,
    },
    {
      name: "ConfirmDeactivation",
      path: "/confirm-profile-deactivation",
      component: ConfirmDeactivation,
    },
    {
      name: "ConfirmDeletion",
      path: "/confirm-profile-deletion",
      component: ConfirmDeletion,
    },
    {
      path: "/register/next",
      name: "NextRegister",
      component: NextRegister,
      // meta: { noAuth: true },
    },
    {
      name: "Project",
      path: "/projects/:id",
      component: Project,
      meta: { headerMenu: true },
    },
    {
      name: "ProjectEdit",
      path: "/projects/:id/edit",
      component: Project,
      props: { editMode: true },
      meta: { headerMenu: true },
      beforeEnter: beforeEnter,
    },
    {
      name: "ProjectCreate",
      path: "/projects/create",
      component: Project,
      props: { editMode: true },
      meta: { headerMenu: true },
      beforeEnter: beforeEnter,
    },
    {
      name: "Product",
      path: "/products/:id",
      component: Product,
      meta: { headerMenu: true },
    },
    {
      name: "ProductEdit",
      path: "/products/:id/edit",
      component: Product,
      props: { editMode: true },
      meta: { headerMenu: true },
      beforeEnter: beforeEnter,
    },
    {
      name: "ProductCreate",
      path: "/products/create",
      component: Product,
      props: { editMode: true },
      meta: { headerMenu: true },
      beforeEnter: beforeEnter,
    },
    {
      name: "Manufacture",
      path: "/manufacture/:id",
      component: Manufacture,
      meta: { headerMenu: true },
    },
    {
      name: "ManufactureEdit",
      path: "/manufacture/:id/edit",
      component: Manufacture,
      props: { editMode: true },
      meta: { headerMenu: true },
      beforeEnter: beforeEnter,
    },
    {
      name: "ManufactureCreate",
      path: "/manufacture/create",
      component: Manufacture,
      props: { editMode: true },
      meta: { headerMenu: true },
      beforeEnter: beforeEnter,
    },
    {
      name: "Review",
      path: "/review",
      redirect: (route) => {
        const initializer = route.query.initializer;
        return `/users/${initializer}`;
      },
    },
    {
      name: "Process",
      path: "/process",
      component: Process,
      meta: { headerMenu: true, mandatoryAuth: true },
    },
    {
      name: "About",
      path: "/about",
      component: About,
      meta: { disableHeader: true, hideFooter: true },
    },
    {
      name: "PrivacyPolicy",
      path: "/privacy",
      component: PrivacyPolicy,
      meta: { headerMenu: true },
    },
    {
      name: "Tariffs",
      path: "/tariffs",
      component: Tariffs,
      meta: { headerMenu: true },
    },
    {
      name: "FeedBack",
      path: "/feedback",
      component: Feedback,
      meta: { headerMenu: true },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: FAQ,
      meta: { headerMenu: true },
    },
    {
      path: "/:catchAll(.*)",
      name: "ErrorPage",
      redirect: () => "/",
    },
    {
      name: "ProfileDelete",
      path: "/profile-delete",
      component: DeleteProfile,
      meta: { headerMenu: true },
    },
    {
      name: "ProfileClose",
      path: "/profile-close",
      component: CloseProfile,
      meta: { headerMenu: true },
    },
    {
      name: "AccountDelete",
      path: "/account-delete",
      component: DeleteAccount,
      meta: { headerMenu: true },
    },
    {
      name: "SuccessMessage",
      path: "/register/success",
      component: SuccessMessage,
      meta: { headerMenu: true },
    },
  ],
});

export default router;
