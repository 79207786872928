<template>
  <div ref="deleteAccountContainer"  class="settings-block delete-account">
    <div  class="delete-account-container">
      <div class="delete-account-back">
        <button class="back-button" data-component="MainSettings" @click="handleClick">
          <img src="@/assets/img/arrow-left.svg" alt="стрілка назад">
          <span>Назад</span>
        </button>
      </div>
      <div class="delete-account-title">Видалення облікового запису</div>
      <div class="delete-account-content content">
        <p class="content-text">
          Ми дуже шкодуємо, {{fullName}}, що ви вирішили видалити свій обліковий запис на платформі PRO Connect.
          Будь ласка, повідомте нам причину цього рішення:
        </p>
        <form @submit.prevent="deleteAccount">
          <div v-for="(reason, index) in reasons" :key="index">
            <input
                type="checkbox"
                :id="'reason-' + index"
                :value="reason"
                v-model="selectedReasons"
            />
            <label :for="'reason-' + index">{{ reason }}</label>
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <PlaceholderTextInput
              typeTag="textarea"
              placeholder="Опис"
              v-model="description"
          />
        </form>
        <p class="content-subtext">
          Ми дуже цінуємо вашу думку і обов’язково приймемо її до уваги, щоб зробити PRO Connect максимально корисним та цікавим для всіх учасників.
          Звертаємо вашу увагу, що після видалення облікового запису ваш профіль, де ви зазначені як "Власник", а також картки всіх ваших проєктів і відгуки будуть видалені. Інші користувачі PRO Connect більше не зможуть їх побачити.
          Після видалення облікового запису, відновити інформацію буде неможливо, і ви одразу втратите доступ до свого облікового запису.
        </p>
      </div>
      <div class="delete-account-buttons">
        <ButtonWhite @click="deleteAccount()"> Видалити</ButtonWhite>
        <Button data-component="MainSettings" @click="handleClick"> Скасувати</Button>
      </div>
      <AccountDeletionModal
          v-if='isPopupVisible === true'
          @closePopup="closePopup">
      </AccountDeletionModal>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button.vue";
import UserService from "@/services/UserService";
import ButtonWhite from "@/components/UI/ButtonWhite.vue";
import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";
import AccountDeletionModal from "@/components/Modals/AccountDeletionModal.vue";

export default {
  data() {
    return {
      selectedReasons: [],
      description: "",
      fullName: null,
      reasons: [
        "У мене є дублікат облікового запису",
        "Мене турбує безпека моїх даних",
        "Я не отримую цінності від користування",
        "Неправдиві відгуки шкодять моїй репутації",
        "Інше"
      ],
      isPopupVisible: "false",
      errorMessage: "",
    };
  },
  methods: {
    async deleteAccount() {
      try {
        if (this.selectedReasons.length === 0) {
          this.showError("Будь ласка, виберіть причину видалення.");
          return;
        }
        const reasonText = this.selectedReasons[0];
        const notes = this.description;
        const response = await UserService.deleteAccountPermanent(reasonText, notes);
        if (response.status === 200) {
          this.isPopupVisible = true;
          this.selectedReasons = [];
          this.description = "";
        } else {
          this.showError("Не вдалося видалити обліковий запис: " + response.data.message);
        }
      } catch (error) {
        this.showError("Сталася помилка: " + error.message);
      }
    },
    handleClick($event) {
      this.$emit("changeComponent", $event);
    },
    scrollToTop() {
      const container = this.$refs.deleteAccountContainer;
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.error('Error');
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    showError(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 3000);
    },
  },
  mounted() {
    if (this.user && this.user.full_name) {
      this.fullName = this.user.full_name;
    } else {
      console.error("Error: Користувача не знайдено.");
    }
    this.scrollToTop();
  },

  components: {AccountDeletionModal, PlaceholderTextInput, ButtonWhite, Button },
};
</script>

<style lang="scss" scoped>
.delete-account {
  .delete-account-container {
    max-height: 100vh;
    overflow-y: auto;
    border-right: 10px;
    background-color: #fff;
    padding: 20px;
    max-width: 728px;
    .delete-account-back {
      padding: 10px 10px 15px 0;
      .back-button {
        display: flex;
        align-items: center;
        gap: 10px;
        border: none;
        background: none;
        color: #6F6F6F;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .delete-account-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .content {
      .content-text {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 30px;
      }
      form {
        margin-bottom: 10px;
        div {
          margin-bottom: 12px;
          input {
            margin: 0.4rem;
          }
          label {
            color: #6F6F6F;
            font-size: 18px;
          }
        }
      }
      .content-subtext {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }
    .delete-account-buttons {
      display: flex;
      gap: 14px;
    }
  }
  .error-message {
    color: red;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
