<template>
  <div ref="closeProfileContainer" class="settings-block close-profile">
    <div class="close-profile-container">
      <div class="close-profile-back">
        <button class="back-button" data-component="MainSettings" @click="handleClick">
          <img src="@/assets/img/arrow-left.svg" alt="стрілка назад">
          <span>Назад</span>
        </button>
      </div>
      <div class="close-profile-title">Тимчасова деактивація профілю</div>
      <div class="close-profile-content content">
        <p class="content-text">Ми дуже шкодуємо,{{activeProfile.name}}, що ви вирішили тимчасово деактивувати свій профіль на
          платформі PRO Connect.
          Будь ласка, повідомте нам причину цього рішення:</p>
        <form @submit.prevent="closeProfileTemporary">
          <div v-for="(reason, index) in reasons" :key="index">
            <input
                type="checkbox"
                :id="'reason-' + index"
                :value="reason"
                v-model="selectedReasons"
            />
            <label :for="'reason-' + index">{{ reason }}</label>
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <PlaceholderTextInput
              typeTag="textarea"
              placeholder="Опис"
              v-model="description"
          />
        </form>
        <p class="content-subtext">Ми цінуємо Вашу думку!
          Дякуємо, що Ви допомогаєте зробити PRO Connect кращим для всіх учасників. Будь ласка, зверніть увагу, що для
          відновлення доступу до вашого профілю, потрібно буде перейти в меню "Профілі" та натиснути кнопку "Відновити"
          біля відповідного профілю. Ваш профіль буде відновлений протягом 48 годин.</p>
      </div>
      <div class="close-profile-buttons">
        <ButtonWhite @click="closeProfileTemporary"> Тимчасово деактивувати</ButtonWhite>
        <Button data-component="MainSettings" @click="handleClick"> Скасувати</Button>
      </div>
      <ProfileClosingModal
          v-if='isPopupVisible === true'
          @closePopup="closePopup">
      </ProfileClosingModal>

    </div>

  </div>
</template>

<script>
import UserService from "@/services/UserService";
import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";
import ButtonWhite from "@/components/UI/ButtonWhite.vue";
import Button from "@/components/UI/Button.vue";
import ProfileClosingModal from "@/components/Modals/ProfileClosingModal.vue";

export default {
  data() {
    return {
      selectedReasons: [],
      description: "",
      accountData: null,
      fullName: null,
      reasons: [
        "У мене є дублікат облікового запису",
        "Мене турбує безпека моїх даних",
        "Я не отримую цінності від користування",
        "Неправдиві відгуки шкодять моїй репутації",
        "Інше"
      ],
      isPopupVisible: "false",
      errorMessage: "",
    };
  },
  methods: {
    async closeProfileTemporary() {
      try {
        if (this.selectedReasons.length === 0) {
          this.showError("Будь ласка, виберіть причину видалення.");
          return;
        }

        const reasonText = this.selectedReasons[0];
        const profileId = this.activeProfile.id;
        const notes = this.description;

        if (!profileId) {
          this.showError("ID профілю відсутнє.");
          return;
        }

        const response = await UserService.closeProfileTemporary(reasonText, profileId,notes);
        if (response && response.status === 200) {
          this.isPopupVisible = true;
          this.selectedReasons = [];
          this.description = "";
          this.notes = "";
        } else {
          this.showError("Не вдалося тимчасово деактивувати профіль.");
        }
      } catch (error) {
        this.showError("Сталася помилка при тимчасовій деактивації профілю: " + error.message);
      }
    },
    handleClick($event) {
      this.$emit("changeComponent", $event);
    },
    scrollToTop() {
      const container = this.$refs.closeProfileContainer;
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.error('Error');
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    showError(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 3000);
    },
  },
  mounted() {
    this.scrollToTop();
  },
  components: {ProfileClosingModal, Button, ButtonWhite, PlaceholderTextInput},
};
</script>


<style lang="scss" scoped>
.close-profile {
  .close-profile-container {
    border-right: 10px;
    background-color: #fff;
    padding: 20px;
    max-width: 728px;

    .close-profile-back {
      padding: 10px 10px 15px 0;

      .back-button {
        display: flex;
        align-items: center;
        gap: 10px;
        border: none;
        background: none;
        color: #6F6F6F;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;

        img {
          width: 20px;
          height: 20px;
        }

      }
    }

    .close-profile-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .content {
      .content-text {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 30px;
      }

      form {
        margin-bottom: 10px;

        div {
          margin-bottom: 12px;

          input {
            margin: 0.4rem;
          }

          label {
            color: #6F6F6F;
            font-size: 18px;
          }
        }
      }

      .content-subtext {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }

    .close-profile-buttons {
      display: flex;
      gap: 14px;
    }
  }
  .error-message {
    color: red;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
