import { createStore } from "vuex";

import UserService from "@/services/UserService";
import $api from "@/http";

import user from "./modules/user";
import toolpic from "./modules/toolpic";
import windows from "./modules/windows";
import filters from "./modules/filters";
import order from "./modules/order";
import Profile from "@/models/profile/Profile";

export default createStore({
  state: {
    listRoles: [],
    listCountries: [],
    listBuildingTypes: [],
    listWorkTypes: [],
    listScale: [],
    listTags: [],
    listCurrency: [],
    listPeriod: [],
    listProfileStatus: [],
    listTopic: [],
    listProfiles: [],
    activeProfile: null,
  },
  mutations: {
    async "set:roles"(state) {
      if (state.listCountries?.length < 1) {
        console.log("listCountries");

        UserService.getAllCountries().then(({ data }) => (state.listCountries = data));
      }

      if (state.listRoles?.length < 1) {
        UserService.getAllRoles().then((resp) => {
          if (resp.statusText == "OK") state.listRoles = resp.data || [];
        });
      }

      if (state.listBuildingTypes?.length < 1) {
        UserService.getAllBuildingTypes().then(({ data }) => {
          state.listBuildingTypes = data;
        });
      }

      if (state.listWorkTypes?.length < 1) {
        UserService.getAllWorkTypes().then(({ data }) => {
          state.listWorkTypes = data;
        });
      }

      if (state.listScale?.length < 1) {
        $api.get("/projects/scale-list/").then((scale) => {
          if (scale.status === 200) state.listScale = scale.data.results;
        });
      }

      if (state.listTags?.length < 1) {
        $api.get("/projects/project-tags-list/").then((tags) => {
          if (tags.status === 200) {
            state.listTags = tags.data;
          }
        });
      }

      if (state.listCurrency?.length < 1) {
        $api.get("/projects/currency-list/").then((currency) => {
          if (currency.status === 200) state.listCurrency = currency.data;
        });
      }

      if (state.listPeriod?.length < 1) {
        $api.get("/projects/period-list/").then((period) => {
          if (period.status === 200) state.listPeriod = period.data;
        });
      }

      if (state.listProfileStatus?.length < 1) {
        $api.get("/users/profile-statuses/").then((statuses) => {
          if (statuses.status === 200) state.listProfileStatus = statuses.data;
        });
      }

      if (state.listTopic?.length < 1) {
        $api.get("/users/support-email-topics/").then((topic) => {
          if (topic.status === 200) state.listTopic = topic.data;
        });
      }
      if (state.user.user.authorized) {
        Profile.getProfilesList().then(({ data }) => {
          state.listProfiles = data.profiles ?? [];
          let idProfile = localStorage.getItem("active_profile");
          if (idProfile) {
            state.activeProfile = state.listProfiles.find((item) => item.id == idProfile) || null;
          } else if (state.listProfiles.length) {
            state.activeProfile = state.listProfiles[0];
          }
          if (state.activeProfile) {
            localStorage.setItem("active_profile", state.activeProfile.id);
          }
        });
      }
    },
    "set:activeProfile"(state, payload) {
      let profile = state.listProfiles.find((item) => item.id == payload);
      if (profile) {
        state.activeProfile = profile;
        localStorage.setItem("active_profile", profile.id);
      }
    },
  },
  actions: {
    loadListRoles({ commit }) {
      return commit("set:roles");
    },
    setActiveProfile({ commit }, data) {
      return commit("set:activeProfile", data);
    },
  },
  getters: {
    listRoles(state) {
      return state.listRoles;
    },
    listCountries(state) {
      return state.listCountries;
    },
    listBuildingTypes(state) {
      return state.listBuildingTypes;
    },
    listWorkTypes(state) {
      return state.listWorkTypes;
    },
    listScale(state) {
      return state.listScale;
    },
    listTags(state) {
      return state.listTags;
    },
    listCurrency(state) {
      return state.listCurrency;
    },
    listPeriod(state) {
      return state.listPeriod;
    },
    listProfileStatus(state) {
      return state.listProfileStatus;
    },
    listTopic(state) {
      return state.listTopic;
    },
    listProfiles(state) {
      return state.listProfiles;
    },
    activeProfile(state) {
      return state.activeProfile;
    },
  },
  modules: {
    user,
    toolpic,
    windows,
    filters,
    order,
  },
});
