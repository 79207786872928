<template>
  <ModalBasic
      @closePopup="closePopup"
  >
    <template v-slot:header>
      <h3>Видалення облікового запису</h3>

    </template>
    <template v-slot:text>
      <p>Лист про підтвердження видалення облікового запису відправлено на вашу електронну пошту.</p>
    </template>
    <template v-slot:close>
      <ButtonExit class="close" @click="closePopup"><img src="@/assets/img/close2.svg"/></ButtonExit>
    </template>
    <template v-slot:buttonCancel>
      <Button @click="closePopup">Ок</Button>
    </template>
  </ModalBasic>

</template>

<script>


import ModalBasic from "@/components/Modals/ModalBasic.vue";
import UserService from "@/services/UserService";

export default {
  name: "InviteFriend",
  components: {ModalBasic},
  data() {
    return {
      email: '',
    }
  },
  methods: {
    async sentInvite() {

      try {
        if (!this.email) return;
        const params = {email: this.email}
        await UserService.inviteFriend(params);
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('closePopup');
      }
    },
    closePopup() {
      this.$emit('closePopup');
    }
  },
}

</script>


<style scoped lang="scss">

.close {
  cursor: pointer;
  border: none;
  padding: 1px;
  background-color: white;

  &:focus {
    transform: scale(1.1);
  }
}

.button-delete {
  padding: 12px 0px;

}
</style>