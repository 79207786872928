<template>
  <div ref="deleteProfileContainer" class="settings-block delete-profile">
    <div class="delete-profile-container">
      <div class="delete-profile-back">
        <button class="back-button" data-component="MainSettings" @click="handleClick">
          <img src="@/assets/img/arrow-left.svg" alt="стрілка назад">
          <span>Назад</span>
        </button>
      </div>
      <div class="delete-profile-title">Видалення профілю</div>
      <div class="delete-profile-content content">
        <p class="content-text">Ми дуже шкодуємо, {{activeProfile.name}}, що ви вирішили видалити свій профіль на платформі PRO Connect.
          Будь ласка, повідомте нам причину цього рішення:</p>
        <form @submit.prevent="deleteProfile">
          <div v-for="(reason, index) in reasons" :key="index">
            <input
                type="checkbox"
                :id="'reason-' + index"
                :value="reason"
                v-model="selectedReasons"
            />
            <label :for="'reason-' + index">{{ reason }}</label>
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <PlaceholderTextInput
              typeTag="textarea"
              placeholder="Опис"
              v-model="description"
          />
        </form>
        <p class="content-subtext">Ми дуже цінуємо вашу думку і обов’язково приймемо її до уваги, щоб зробити PRO Connect максимально корисним та цікавим для всіх учасників.
          Звертаємо вашу увагу, що після видалення профілю картки всіх ваших проєктів, зв'язки та відгуки цього профілю будуть видалені, і інші користувачі PRO Connect більше не зможуть їх побачити.
          Після видалення профілю, відновити інформацію буде неможливо, і ви одразу втратите доступ до свого профілю.</p>
      </div>
      <div class="delete-profile-buttons">
        <ButtonWhite @click="deleteProfile"> Видалити</ButtonWhite>
        <Button data-component="MainSettings" @click="handleClick"> Скасувати</Button>
      </div>
      <ProfileDeletionModal
          v-if='isPopupVisible === true'
          @closePopup="closePopup">
      </ProfileDeletionModal>

    </div>

  </div>
</template>

<script>
import Button from "@/components/UI/Button.vue";
import UserService from "@/services/UserService";
import ButtonWhite from "@/components/UI/ButtonWhite.vue";
import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";
import ProfileDeletionModal from "@/components/Modals/ProfileDeletionModal.vue";

export default {
  data() {
    return {
      selectedReasons: [],
      description: "",
      accountData: null,
      fullName: null,
      reasons: [
        "У мене є дублікат облікового запису",
        "Мене турбує безпека моїх даних",
        "Я не отримую цінності від користування",
        "Неправдиві відгуки шкодять моїй репутації",
        "Інше"
      ],
      isPopupVisible: false,
      errorMessage: "",
    };
  },
  methods: {
    async deleteProfile() {
      try {
        if (this.selectedReasons.length === 0) {
          this.showError("Будь ласка, виберіть причину видалення.");
          return;
        }

        const reasonText = this.selectedReasons[0];
        const profileId = this.activeProfile.id;
        const notes = this.description;

        if (!profileId) {
          this.showError("ID профілю відсутнє.");
          return;
        }

        const response = await UserService.deleteProfilePermanent(reasonText, profileId, notes);
        if (response && response.status === 200) {
          this.isPopupVisible = true;
          this.selectedReasons = [];
          this.description = "";
          this.notes = "";
        } else {
          this.showError("Не вдалося видалити профіль.");
        }
      } catch (error) {
        this.showError("Сталася помилка при видаленні профілю: " + error.message);
      }
    },
    handleClick($event) {
      this.$emit("changeComponent", $event);
    },
    scrollToTop() {
      const container = this.$refs.deleteProfileContainer;
      if (container) {
        container.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        console.error("Error");
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    showError(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = "";
      }, 3000);
    },
  },
  mounted() {
    this.scrollToTop();
  },
  components: { ProfileDeletionModal, PlaceholderTextInput, ButtonWhite, Button },
};
</script>

<style lang="scss" scoped>
.delete-profile {
  .delete-profile-container {
    background-color: #fff;
    padding: 20px;
    max-width: 728px;

    .delete-profile-back {
      padding: 10px 10px 15px 0;

      .back-button {
        display: flex;
        align-items: center;
        gap: 10px;
        border: none;
        background: none;
        color: #6F6F6F;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .delete-profile-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .content {
      .content-text {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 30px;
      }
      form {
        margin-bottom: 10px;

        div {
          margin-bottom: 12px;

          input {
            margin: 0.4rem;
          }

          label {
            color: #6F6F6F;
            font-size: 18px;
          }
        }
      }

      .content-subtext {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
        line-height: 30px;
      }
    }

    .delete-profile-buttons {
      display: flex;
      gap: 14px;
    }
  }

  .error-message {
    color: red;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
