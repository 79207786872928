import BaseModel from "../BaseModel";
import $api from "@/http";

export default class Profile extends BaseModel {
  constructor(values) {
    const data = values ?? {};
    data.roles = data.roles ?? [];
    data.work_types = data.work_types ?? [];
    data.building_types = data.building_types ?? [];
    data.community_membership = data.community_membership ?? [];

    super(data);
    this.name = data.name;
    this.profile_type = data.profile_type ?? "company";
    this.description = data.description;
    this.projects_count = data.projects_count;
    this.reviews_count = data.reviews_count;
    this.rating = data.rating;
    this.country = data.country;
    this.office_location = data.office_location;
    this.photo = data.photo;
    this.cover_photo = data.cover_photo;
    this.experience_years = data.experience_years ?? 0;
    this.team_size = data.team_size;
    this.linkedin = data.linkedin;
    this.facebook = data.facebook;
    this.youtube = data.youtube;
    this.instagram = data.instagram;
    this.telegram = data.telegram;
    this.whatsapp = data.whatsapp;
    this.contact_email = data.contact_email;
    this.phone = data.phone;
    this.youcontrol = data.youcontrol;
    this.personal_site = data.personal_site;
    this.is_propm_family = data.is_propm_family ?? false;
    this.is_mvp = data.is_mvp ?? false;
    this.is_verified = data.is_verified ?? false;
    this.is_hibernated = data.is_hibernated ?? false;
    this.created_datetime = data.created_datetime;
    this.token = data.token;
    this.roles = data.roles ?? [];
    this.work_types = data.work_types ?? [];
    this.building_types = data.building_types ?? [];
    this.community_membership = data.community_membership ?? [];
  }

  getData() {
    const dataToSave = super.getData();
    if (typeof dataToSave.photo == "string") delete dataToSave.photo;
    return dataToSave;
  }

  async save() {
    const formData = this.getData();
    if (this.id) {
      return await Profile.updateProfile(this.id, formData);
    }
    const resp = await Profile.createProfile(formData);
    if (resp.status < 400) {
      this.id = resp.data.id;
    }

    return resp;
  }

  static async load(id) {
    let resp = await $api.get(`/users/get-profile/${id}/`);
    if (resp.status !== 200) return false;
    return resp.data;
  }

  static async createProfile(data) {
    return $api.post(`/users/create-profile/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  static async updateProfile(id, data) {
    return $api.patch(`/users/update-profile/${id}/`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static async getProfilesList() {
    return await $api.get(`/users/profiles-list/`);
  }
  static async getList(params) {
    return await $api.get(`/users/search-profiles/`, { params });
  }
}
