<template>
  <div class="success-message">
    <h2>Вітаємо!</h2>
    <p>Для завершення реєстрації, будь ласка, перевірте свою електронну пошту та підтвердіть адресу.</p>
  </div>
</template>

<script>
export default {
  name: "SuccessMessage",
};
</script>

<style scoped>
.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success-message h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.success-message p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 30px;
}
</style>
