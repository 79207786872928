<template>
  <button
      :stroke="stroke"
      :class="['ui-button', this.class]"
      :disabled="disabled"
  >
    <div>
      <slot></slot>
    </div>
    <div v-if="true" >
      <Loading style="width: 20px;height: 20px;padding: 10px 0"
               v-show="btnLoading"
      />
    </div>


  </button>
</template>

<script>
export default {
  name: "ButtonWhite",
  props: {
    btnLoading: {type: Boolean, default: false},
    stroke: {type: Boolean, required: false, default: false},
    disabled: {type: Boolean, required: false, default: false},
    class: {type: String, required: false, default: ""},
    paddingsForButton:{type:Boolean, default:false},
  },

};
</script>

<style lang="scss" scoped>
.ui-button {
  display: flex;
  cursor: pointer;
  text-align: center;
  padding: 11px 32px;
  width: 100%;
  border-radius: 6px;
  background-color: white;
  gap: 12px;
  font-size: 16px;
  color: #252525;
  font-family: var(--font-primary);
  justify-content: center;
  border: 1px solid #43B6F5;


  &:hover {
    border: 1px solid #2A9DF4;
    transition: all 0.2s ease 0s;

  }

  &:active {
    border: 1px solid #2A9DF4;
  }
  &:focus {
    border: 1px solid #2A9DF4;
    transition: all 0.2s ease 0s;
  }


  &:disabled {
    background: #b4b4b4 !important;
  }

}
</style>
